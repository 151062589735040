<template>
	<div class="gg-container" v-loading.fullscreen.lock="fullscreenLoading" style="overflow: visible ;padding: 0">
		<el-button type="danger" @click="closeDrawer"
							 style="position: absolute; display: flex; justify-content: center; align-items: center; border: 0; border-radius: 4px 0 0 4px; top: 140px; left: -40px;width: 40px; height: 40px;">
			<i class="iconfont icon-cha" style="font-size: 24px"></i>
		</el-button>
		<div style="padding: 20px;font-size: 14px;">
			<el-row>
				<el-col :span="6">
					<div class="grid-content bg-purple relation-container">
						<div style="display: flex;  align-items: center;">
							<div class="required"></div><span>处方信息</span>
						</div>
					</div>
				</el-col>
				<el-col :span="16">
					<div class="grid-content bg-purple-light"></div>
				</el-col>
				<el-col :span="2" style="display: flex;justify-content: flex-end;">
					<el-dropdown trigger="click" @command="handleCommand">
				<span class="el-dropdown-link">
					...
				</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="handleCheck">审核</el-dropdown-item>
							<el-dropdown-item command="handleDelete">删除</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12" class="grid-content bg-purple relation-container">
					<span class="title-pre-l">
						处方编号
					</span>
					<span>
						{{info.pres_sn}}
					</span>
				</el-col>
				<el-col :span="12" class="grid-content bg-purple relation-container">
					<span class="title-pre-l">
						科室
					</span>
					<span>
						{{info.depart_name}}
					</span>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12" class="grid-content bg-purple relation-container">
					<span class="title-pre-l">
						姓名
					</span>
					<span>
						{{info.patient_name}}
					</span>
				</el-col>
				<el-col :span="12" class="grid-content bg-purple relation-container">
					<span class="title-pre-l">
						性别
					</span>
					<span>
						{{info.patient_gender}}
					</span>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12" class="grid-content bg-purple relation-container">
					<div class="title-pre-l">
						电话
					</div>
					<div class="grid-content bg-purple-light relation-container number-align">
						{{info.patient_mobile}}
					</div>
				</el-col>
				<el-col :span="12" class="grid-content bg-purple-light relation-container">
					<div class="title-pre-l">
						诊断
					</div>
					<div>
						{{info.debate}}
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12" class="grid-content bg-purple relation-container">
					<div class="title-pre-l">
						审核状态
					</div>
					<div class="grid-content bg-purple-light relation-container number-align">
						{{info.examine_status | formatExamineStatus}}
					</div>
				</el-col>
				 <el-col :span="12" class="grid-content bg-purple-light relation-container">
					<div class="title-pre-l">
						<!--拒绝原因-->
					</div>
					 <div>
						 <!--{{info.reject_reason}}-->
					 </div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12" class="grid-content bg-purple relation-container">
					<div class="title-pre-l">
						开方医师
					</div>
					<div>
						{{info.doctor_name}}
					</div>
				</el-col>
				<el-col :span="12" class="grid-content bg-purple-light relation-container">
					<div class="title-pre-l">
						审方药师
					</div>
					<div>
						{{info.druggist_one_name}}
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="2" class="grid-content bg-purple-light relation-container">
					<div class="title-pre-l">
						处方笺
					</div>
			</el-col>
				<el-col :span="10">
					<div>
						<div @mouseenter="enter()" @mouseleave="leave()"
								 @click="under(info.pres_pics, 'drawerPic')" style="position: relative" v-if="info.pres_pics && info.pres_pics.length > 0">
							<transition name="fade">
								<div v-if="seen"
										 style="position: absolute;background-color: rgba(101, 101, 101, .3); width: 90px; height: 90px; z-index: 3999">
								</div>
							</transition>
							<i v-show="seen" class="el-icon-zoom-in"
								 style="position: absolute;top: 50%;left: 50%; transform: translate(-50%, -50%); font-size:26px; color: #fff; width: 30px; height: 30px; z-index: 4000"></i>
							<el-image ref="drawerPic" style="width: 90px; height: 90px;" :z-index=zIndex
												:src="info.pres_pics instanceof Array ? info.pres_pics[0] : '' "
												:preview-src-list="info.pres_pics instanceof Array ? info.pres_pics : [] ">
							</el-image>
						</div>
						<div v-else class="grid-content bg-purple-light relation-container" style="margin-left: 2px">
							暂无
						</div>
					</div>
				
				</el-col>
			</el-row>
			
			<!--成药方子表格-->
			<el-row v-if="tableDataDrawer.length" class="margin-t-30">
				<el-col :span="6">
					<div class="grid-content bg-purple relation-container">
						<div style="display: flex;  align-items: center;">
							<div class="required"></div><span>成药方子</span>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple-light"></div>
				</el-col>
			</el-row>
			<el-table :data="tableDataDrawer" v-if="tableDataDrawer.length" border style="width: 100%">
				<el-table-column prop="drug_name" label="名称" align="center" width="180">
				</el-table-column>
				<el-table-column prop="sn" label="编号" align="center" width="180">
				</el-table-column>
				<el-table-column prop="unit" label="规格" align="center" width="120">
				</el-table-column>
				<el-table-column prop="dosage_unit" label="剂型" align="center" width="240">
				</el-table-column>
				<el-table-column prop="count" label="数量" align="center" width="80">
				</el-table-column>
				<el-table-column prop="drug_price" label="单价" align="center" width="160">
				</el-table-column>
				<el-table-column prop="usage" label="用药建议" align="center" width="270">
				</el-table-column>
				<el-table-column prop="pres_not" label="是否处方药" align="center" width="160">
					<template slot-scope="scope">
						{{scope.row.pres_not | isPres }}
					</template>
				</el-table-column>
			</el-table>
			
			<!-- 中药方子 -->
			<el-row class="margin-t-30"  v-if="tcDrugs.length">
				<el-col :span="6">
					<div class="grid-content bg-purple relation-container">
						<div style="display: flex;  align-items: center;">
							<div class="required"></div><span>中药方子</span>
						</div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="grid-content bg-purple-light"></div>
				</el-col>
			</el-row>
			<el-row v-if="tcDrugs.length">
				<el-col :span="24">
					<div class="grid-content bg-purple relation-container">
						<span style="color: #333333">{{this.tcDrugs.join(' ；')}}</span>
					</div>
				</el-col>
			</el-row>
			<el-row v-if="tcDrugs.length">
				<el-col :span="24">
					<div class="grid-content bg-purple relation-container">
						<span style="color: #333333">用药建议及医嘱: {{info.taboo || '没有'}}</span>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import {delPresById} from '@/api/informationSearch';

export default {
  name: "presDetail",
	props: {
    info: {
      type: Object
		},
    tcDrugs: {
      type: Array,
			default: () => []
		},
		tableDataDrawer: {
      type: Array,
			default: () => []
		}
	},
	data() {
    return {
      fullscreenLoading: false,
      // 图片遮罩相关
      seen: false,
      current: 0,
      isPicIdx: '',
      // 默认图片层级
      zIndex: 2000,
    }
	},
  filters: {
    formatExamineStatus(v) {
      if (v === 0) {
        return '待审核'
      } else if (v === 1)
        return '初审通过'
      else if (v === 2) {
        return '复审通过'
      } else {
        return '审核拒绝'
      }
    },
    isPres(v) {
      if (v) {
        return '是处方药'
			} else {
        return '非处方药'
			}
		}
  },
	methods: {
    closeDrawer() {
      console.log(123)
      this.$emit('closeDrawer', false)
    },
		getList() {
      this.$emit('getList')
			this.closeDrawer()
		},
    handleCommand(command) {
      // this.$message('click on item ' + command);
      if (command === 'handleDelete') {
        this.handleDelete()
			} else if (command === 'handleCheck') {
   			this.handleCheck()
			}
    },
    handleDelete() {
      console.log(this.info.pres_id);
      this.$confirm('确认删除处方?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPresById(this.info.pres_id).then(res => {
          if (res.code == 200) {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

		handleCheck() {
  
		},
    //鼠标移入移出
    enter(index) {
      this.seen = true;
      this.current = index;
      this.isPicIdx = index
    },
    leave() {
      console.log('leave')
      this.seen = false;
      this.current = null;
      this.isPicIdx = null;
    },
    // 不同层级指定不同refs
    under(pics, refPic = 'pic') {
      console.log(pics);
      if (pics.length > 0) {
        console.log(123)
        this.zIndex = 9999
        this.$refs[refPic].showViewer = true
      }
    },
	}
}
</script>

<style scoped lang="scss">
	.margin-t-30{
		margin-top: 30px;
	}
	/*竖杠+后面文本的样式*/
	.required {
		margin-top: 2px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}
	
	.required+span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	/*右侧下拉框样式*/
	.el-dropdown-link {
		display: inline-block;
		width: 30px;
		height: 20px;
		border: 1px solid #dfe4ed;
		text-align: center;
		line-height: 10px;
		&:hover {
			cursor: pointer;
		}
	}
	
	/*说明文字样式*/
	.title-pre-l{
		width: 120px;
		color: #777
	}
	
	/*栅格文本居中*/
	.relation-container {
		display: flex;
		/*justify-content:center;!*主轴上居中*!*/
		align-items: center;
		/*侧轴上居中*/
	}
	
	
	/*栅格边框*/
	.el-row {
	
	/*margin-bottom: 20px;*/
	&:last-child {
		 margin-bottom: 0;
	 }
	}
	
	.el-col {
		border-radius: 4px;
	}
	
	/*测试排版使用颜色*/
	/*.bg-purple-dark {*/
	/*	background: #99a9bf;*/
	/*}*/
	
	/*.bg-purple {*/
	/*	background: #d3dce6;*/
	/*}*/
	
	/*.bg-purple-light {*/
	/*	background: #e5e9f2;*/
	/*}*/
	
	.grid-content {
		border-radius: 4px;
		min-height: 30px;
	}
	
	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
</style>
