var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('el-dialog',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],attrs:{"visible":_vm.isActivePresTableHead,"width":"1200px","inline":"","close-on-click-modal":false,"before-close":_vm.handleCancel},on:{"update:visible":function($event){_vm.isActivePresTableHead=$event}}},[_c('div',{staticStyle:{"font-size":"14px","color":"#606266","font-weight":"700","margin-bottom":"15px"},attrs:{"slot":"title"},slot:"title"}),_c('el-table',{ref:"dragTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"fit":"","highlight-current-row":"","border":""}},[_c('el-table-column',{attrs:{"prop":"sort","width":"50","label":"排序","align":"center"}}),_c('el-table-column',{attrs:{"prop":"column_prop","label":"表头字段","width":"150","align":"center"}}),_c('el-table-column',{attrs:{"prop":"column_label","label":"默认名称","width":"120","align":"center"}}),_c('el-table-column',{attrs:{"prop":"field_type","label":"数据类型","width":"160","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.edit)?[_c('el-input',{staticClass:"edit-input",attrs:{"size":"mini"},model:{value:(row.field_type),callback:function ($$v) {_vm.$set(row, "field_type", $$v)},expression:"row.field_type"}}),_c('el-button',{staticClass:"cancel-btn",attrs:{"size":"mini","icon":"","type":"warning"},on:{"click":function($event){return _vm.handleCancelEditLabel(row)}}},[_vm._v(" 退出 ")])]:_c('span',[_vm._v(_vm._s(row.field_type))])]}}])}),_c('el-table-column',{attrs:{"prop":"column_label_user_definition","label":"自定义名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.edit)?[_c('el-input',{staticClass:"edit-input",attrs:{"size":"mini"},model:{value:(row.column_label_user_definition),callback:function ($$v) {_vm.$set(row, "column_label_user_definition", $$v)},expression:"row.column_label_user_definition"}}),_c('el-button',{staticClass:"cancel-btn",attrs:{"size":"mini","icon":"","type":"warning"},on:{"click":function($event){return _vm.handleCancelEditLabel(row)}}},[_vm._v(" 退出 ")])]:_c('span',[_vm._v(_vm._s(row.column_label_user_definition))])]}}])}),_c('el-table-column',{attrs:{"prop":"width","label":"自定义宽度","align":"center","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[(row.edit)?[_c('el-input',{staticClass:"edit-input",attrs:{"size":"mini"},model:{value:(row.width),callback:function ($$v) {_vm.$set(row, "width", _vm._n($$v))},expression:"row.width"}}),_c('el-button',{staticClass:"cancel-btn",attrs:{"size":"mini","icon":"","type":"warning"},on:{"click":function($event){return _vm.handleCancelEditWidth(row)}}},[_vm._v(" 退出 ")])]:_c('span',[_vm._v(_vm._s(row.width))])],2)]}}])}),_c('el-table-column',{attrs:{"prop":"visible","label":"是否显示","align":"center","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-switch',{staticClass:"switch",attrs:{"active-value":true,"inactive-value":false,"active-text":"启用","inactive-text":"禁用","active-color":"#13ce66","inactive-color":"#C8C8C8"},on:{"change":function($event){return _vm.changeExamine(scope.row)}},model:{value:(scope.row.visible),callback:function ($$v) {_vm.$set(scope.row, "visible", $$v)},expression:"scope.row.visible"}})]}}])}),_c('el-table-column',{attrs:{"align":"center","width":"110","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.edit)?_c('el-button',{attrs:{"type":"success","size":"mini","icon":"el-icon-check"},on:{"click":function($event){return _vm.handleConfirmEdit(row)}}},[_vm._v(" 确认 ")]):_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-edit"},on:{"click":function($event){row.edit=!row.edit}}},[_vm._v(" 编辑 ")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"改变位置","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('i',{staticClass:"drag-handler el-icon-rank"})]}}])})],1),_c('div',{staticClass:"show-d"},[_c('el-tag',[_vm._v("原始排序 :")]),_vm._v(" "+_vm._s(_vm.oldList)+" ")],1),_c('div',{staticClass:"show-d"},[_c('el-tag',[_vm._v("更新排序 :")]),_vm._v(" "+_vm._s(_vm.newList)+" ")],1),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","margin-top":"10px"}},[_c('el-button',{staticStyle:{"margin-right":"40px"},attrs:{"size":"mini","type":"info"},on:{"click":function($event){return _vm.handleCancelTable()}}},[_vm._v(" 退出 ")]),_c('el-button',{staticStyle:{"background-color":"#2362FB","border-color":"#2362FB"},attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.handleSubmitTable()}}},[_vm._v("提交 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }