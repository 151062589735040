<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->

      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <!-- <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
import EditTableHead from "../../components/editTableHead/editTableHead.vue";
                                  background-color: #2362FB; border-color: #2362FB"
            @click="handleUpLoad(multipleSelectionPres[0])"
            v-if="multipleSelectionPres.length == 1"
            >点击上传
          </el-button>
        </div> -->
        <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                  background-color: #2362FB; border-color: #2362FB"
            @click="delDrug(multipleSelectionPres)"
            v-if="multipleSelectionPres.length == 1"
            >删除
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                background-color: #2362FB; border-color: #2362FB"
            @click="delDrug(multipleSelectionPres)"
            v-if="multipleSelectionPres.length > 1"
            >批量删除
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                  background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                  background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              v-if="
                scope.row.pres_pics.length > 0 &&
                  column.column_label == '处方笺'
              "
              style="font-size: 12px; color: #2379fb"
              @click.prevent="getLookImg(scope.row)"
            >
              查看处方笺
            </a>
            <a
              v-else-if="column.column_label == '处方编号'"
              style="font-size: 12px; color: #2379fb"
              @click.prevent="getDetail(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
            <a v-else>
              暂无
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'imageBtn' && column.column_label == '处方笺' && column.visible === true"
          show-overflow-tooltip
        >
        <template slot-scope="scope">
            <div
            @click="under(scope.row.pres_pics, 'pic' + scope.$index)"
              v-if="scope.row.pres_pics.length > 0"
            >
            <a style="color:#2362FB" @click="img">查看图片</a>
              <el-image
                :ref="'pic' + scope.$index"
                style="width: 0; height: 0;"
                :z-index="zIndex"
                :src="
                  scope.row.pres_pics instanceof Array
                    ? scope.row.pres_pics[0]
                    : ''
                "
                :preview-src-list="
                  scope.row.pres_pics instanceof Array
                    ? scope.row.pres_pics
                    : []
                "
              >
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
            :prop="column.column_prop"
            :label="
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            "
            :width="column.width ? column.width : ''"
            :key="index"
            align="center"
            v-else-if="column.field_type === 'text'&&column.visible === true"
            show-overflow-tooltip
          >
  
          </el-table-column> -->
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'text' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a v-if="column.column_label == '上传状态'">
              {{ scope.row[column.column_prop] | formatSupervise_status }}
            </a>
            <a v-else-if="column.column_label == '扭转状态'">
              {{ scope.row[column.column_prop] | formateFromSource }}
            </a>
            <a v-else-if="column.column_label == '处方状态'">
              {{ scope.row[column.column_prop] | formatExamineStatus }}
            </a>
            <a v-else-if="column.column_label == '领取状态'">
              {{ scope.row[column.column_prop] | formatDrawStatus }}
            </a>
            <a v-else-if="column.column_label == '过期状态'">
              {{ scope.row[column.column_prop] | formatOverdueStatus }}
            </a>
            <a v-else>{{ scope.row[column.column_prop] }}</a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'flow_status_name' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(
                    column.column_prop,
                    searchParams.flow_status_name
                  )
                "
                v-model="searchParams.flow_status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <template v-for="item in flow_status_options">
                  <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-if="'examine_status' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.examine_status)
                "
                v-model="searchParams.examine_status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <template v-for="item in pres_status">
                  <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-input
                v-if="'order_sn' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.order_sn"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.order_sn)
                "
              >
              </el-input>
              <el-input
                v-if="'patient_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.patient_name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.patient_name)
                "
              >
              </el-input>
              <el-input
                v-if="'doctor_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.doctor_name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.doctor_name)
                "
              >
              </el-input>
              <el-input
                v-if="'patient_mobile' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.patient_mobile"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.patient_mobile)
                "
              >
              </el-input>
              <el-input
                v-if="'druggist_one_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.druggist_one_name"
                clearable
                @change="
                  handleSearch(
                    column.column_prop,
                    searchParams.druggist_one_name
                  )
                "
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <EditTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </EditTableHead>
    <el-drawer
      v-loading="listLoading"
      :before-close="handleCloseDrawer"
      @closeDrawer="handleCloseDrawer"
      title=""
      size="76%"
      :visible.sync="drawerVisible"
      :with-header="false"
    >
      <presDetail
        @closeDrawer="closeDrawer"
        @getList="getList"
        :info="info"
        :tcDrugs="tcDrugs"
        :tableDataDrawer="tableDataDrawer"
        v-if="drawerVisible"
      ></presDetail>
    </el-drawer>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import presDetail from "./components/presDetail";
import {
  getPresList,
  getPresDetail,
  delPresById,
  statusUpload,
  deletePres,
} from "@/api/informationSearch";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import presTableHead from "./tableHead/presTableHead";
import { mapState } from "vuex";
import EditTableHead from "@/components/editTableHead/editTableHead.vue";
export default {
  name: "pres",
  components: {
    Pagination,
    // eslint-disable-next-line vue/no-unused-components
    presDetail,
    // presTableHead,
    EditTableHead
},
  async created() {
    this.is_admin = localStorage.getItem("is_admin");
    await this._getAdminFieldIndex();
    this.getList();
    // const res = await getPresList();
    // console.log(res);
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  data() {
    return {
      table_type:'pres',
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "order_sn",
          isSearch: false,
        },
        {
          name: "druggist_one_name",
          isSearch: false,
        },
        {
          name: "patient_name",
          isSearch: false,
        },
        {
          name: "patient_mobile",
          isSearch: false,
        },
        {
          name: "doctor_name",
          isSearch: false,
        },
        {
          name: "flow_status_name",
          isSearch: false,
        },
        {
          name: "examine_status",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      superviseStatusList: [
        {
          value: "1",
          label: "未上传",
        },
        {
          value: "2",
          label: "待上传",
        },
        {
          value: "3",
          label: "已上传",
        },
      ],
      is_admin: "", //账号身份
      info: {},
      showPop: false,
      selected: false,
      clearFilter: false,
      srcList: [],
      colors: [
        "#15ce45",
        "#0091ff",
        "#bc63fc",
        "#FCC810",
        "#2362FB",
        "#FF3300",
      ],
      flow_status_options: [
        { label: "未流转", value: 1 },
        { label: "待流转", value: 2 },
        { label: "已流转", value: 3 },
      ],
      examine_status: [
        {
          value: "-1",
          label: "不用审核",
        },
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "初审通过",
        },
        {
          value: "2",
          label: "复审通过",
        },
        {
          value: "3",
          label: "审核拒绝",
        },
        {
          value: "4",
          label: "签章中",
        },
      ],
      pres_status: [
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "一审通过",
        },
        {
          value: "2",
          label: "二审通过",
        },
        {
          value: "3",
          label: "审核拒绝",
        },
        {
          value: "4",
          label: "签章中",
        },
      ],
      supervise_status: [
        {
          value: "1",
          label: "未上传",
        },
        {
          value: "2",
          label: "待上传",
        },
        {
          value: "3",
          label: "已上传",
        },
      ],
      draw_status: [
        {
          value: "1",
          label: "待领取",
        },
        {
          value: "2",
          label: "已领取",
        },
      ],
      pres_source: [
        {
          value: "1",
          label: "慢病场景",
        },
        {
          value: "2",
          label: "医生场景",
        },
        {
          value: "3",
          label: "患者场景",
        },
      ],
      tableData: [],
      dataDrawer: {},
      tcDrugs: [],
      tableDataDrawer: [],
      searchParams: {
        orderTime: [],
        pres_sn: "",
        doctors_name: "",
        druggist_name: "",
        patient_name: "",
      },
      listQuery: {
        page: 1,
        limit: 20,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      listLoading: false,
      moreCondition: false,
      drawerVisible: false,
      // 图片遮罩相关
      seen: false,
      current: 0,
      isPicIdx: "",
      // 默认图片层级
      zIndex: 2000,
      importanceOptions: [
        {
          value: "1",
          label: "审核",
        },
        // {
        // 	value: '2',
        // 	label: '删除'
        // },
      ],
      importance: undefined,
      flag:false
    };
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.is_admin,
      table_options: (state) => state.user.table_options,
    }),
  },
  filters: {
    formatExamineStatus(v) {
      if (v === 0) {
        return "待审核";
      } else if (v === 1) return "一审通过";
      else if (v === 2) {
        return "二审通过";
      }
      if (v === 3) {
        return "审核拒绝";
      }
      if (v === 4) {
        return "签章中";
      }
      if (v === -1) {
        return "不用审核";
      }
    },
    formatDrawStatus(v) {
      if (v === 1) {
        return "待领取";
      } else if (v === 2) return "已领取";
      else {
        return v;
      }
    },
    formatOverdueStatus(v) {
      if (v === 0) {
        return "未过期";
      } else if (v === 1) return "已过期";
      else {
        return v;
      }
    },
    formatSource(v) {
      if (v === 1) {
        return "患者场景";
      } else if (v === 2) return "医师场景";
      else {
        return "慢病场景";
      }
    },
    formatSupervise_status(v) {
      if (v === 1) {
        return "未上传";
      } else if (v === 2) return "待上传";
      else {
        return "已上传";
      }
    },
  },
  methods: {
    img(){
      this.flag = true
    },
    handleCustomerDetail(row, e) {
      console.log(row, e);
      if (e == "doctor_name") {
        this.getInfo(row.doctors_id);
      } else if (e == "professionalName") {
        this.handleSetInquiryPrice(row);
      } else if (e == "show_sort") {
        this.handleSort(row);
      } else if (e == "is_bind") {
        this.handleAssistant(row);
      }
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "pres",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.pres;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    handleUploadStatus(row) {
      statusUpload(row.pres_id, row.is_upload).then((res) => {
        if (200 == res.code) {
          console.log(res);
        }
      });
    },
    //清除高级搜索
    clearState() {
      this.searchParams.status = "";
      this.searchParams.upload_status = "";
      this.clearText();
    },
    approvalStatus(e) {
      this.searchParams.status = e;
    },
    clearText() {
      this.searchParams.pres_sn = "";
      this.searchParams.doctors_name = "";
      this.searchParams.druggist_name = "";
      this.searchParams.member_keyword = "";
      this.searchParams.examine_status = "";
      this.searchParams.draw_status = "";
      this.searchParams.supervise_status = "";
      this.searchParams.source = "";
      this.searchParams.pres_status = "";
      this.getList();
    },
    popoShow() {
      this.clearFilter = true;
      this.selected = true;
    },
    popoHide() {
      this.selected = false;
      this.clearFilter = false;
    },
    getList(rest) {
      if (rest === "restPage") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.page_size = this.listQuery.limit;
      getPresList(params)
        .then((response) => {
          let data = response;
          if (data.code === 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    getDetail(row) {
      this.drawerVisible = !this.drawerVisible;
      this.listLoading = true;
      let params = {
        pres_id: row.pres_id,
      };
      getPresDetail(params)
        .then((response) => {
          let data = response;
          if (data.code === 200) {
            this.info = data.data;
            if (data.data.tc_drugs) {
              this.tcDrugs = data.data.tc_drugs;
              this.tcDrugs = this.tcDrugs.map((v, k, a) => {
                return `${v.drug_name.split(" ").join("")} ${v.count}克`;
              });
            }
            this.tableDataDrawer = data.data["w_drugs"];
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    cellStyle(row, column, rowIndex, columnIndex) {
      if (row.column.label === "处方编号") {
        return "color: #2632fb";
      }
    },
    //上传
    handleUpLoad(row) {
      this.$confirm("是否上传处方?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(row.appraise_id);
          statusUpload(row.pres_id, row.is_upload).then((res) => {
            if (200 == res.code) {
              this.getList();
              this.$message({
                type: "success",
                message: "上传成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消上传",
          });
        });
    },
    delDrug(row) {
      console.log(row)
      let pres_id = [];
      row.forEach((item) => {
        //将item.diag_id push进
        pres_id.push(item.pres_id);
      });
      console.log(row);
      this.$confirm("确认删除处方?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // console.log(row.appraise_id);
          delPresById({pres_id}).then((res) => {
            if (res.code == 200) {
              this.getList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }else{
              this.$message({
                type:'error',
                message:res.msg
              })
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //鼠标移入移出
    enter(index) {
      this.seen = true;
      this.current = index;
      this.isPicIdx = index;
    },
    leave() {
      console.log("leave");
      this.seen = false;
      this.current = null;
      this.isPicIdx = null;
    },
    // 不同层级指定不同refs
    under(pics, refPic = "pic") {
      // console.log(pics,refPic);
      if (pics.length > 0) {
        console.log(123,this.$refs[refPic][0],596458645);
        this.zIndex = 9999;
        this.$refs[refPic][0].showViewer = true;
      }
    },
    handleCloseDrawer() {
      this.dataDrawer = {};
      this.tableDataDrawer = [];
      this.drawerVisible = false;
    },
    //关闭弹窗,刷新列表
    closeDrawer(e) {
      this.drawerVisible = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.isSearch {
  color: #f56c6c;
}

.search-icon {
  color: #f56c6c;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

/*抽屉高度*/
/deep/ .el-drawer {
  bottom: 0 !important;
}

/deep/ .el-drawer__header {
  padding: 0;
  margin: 0;
}

/deep/ .el-drawer.rtl {
  overflow: visible;
  height: 100vh;
  bottom: 0;
  margin-top: 0vh;
  position: absolute;
}
/deep/ .el-table .warning-row {
  color: red;
}
</style>
